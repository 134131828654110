import React, { Component, Fragment } from "react";
import styled from "styled-components";
import * as Icon from "../Icon";
import AppConfig from "../../Config";
import TreeNodeWrapper from "../FilterPanelTreeNode";

const OwnConfig = AppConfig["Components/FilterPanel"];

class FilterPanel extends Component {
  render() {
    return (
      <>
        <DesktopFilterPanel {...this.props} />
        <MobileFilterPanel {...this.props} />
      </>
    );
  }
}

const FilterTitle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  font-size: 14px;
  color: #4e4e4e;
  ${props => props.css}
`;

class DesktopFilterPanel extends Component {
  render() {
    let { activeFilter, onFilterItemClick, labels } = this.props;
    return (
      <Wrapper>
        <FilterTitle>
          <Icon.Menu color="#4e4e4e" size={24} style={{ marginRight: 10 }} />
          <div>分類</div>
        </FilterTitle>

        {labels.map((label, lidx) => {
          if (label.children.length > 0) {
            return (
              <Fragment key={lidx}>
                {label.children.map((children, index) => (
                  <TreeNodeWrapper
                    key={index}
                    onNodeClick={node => onFilterItemClick(node)}
                    activeNode={activeFilter}
                    node={children}
                  />
                ))}
              </Fragment>
            );
          }
        })}
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
  @media screen and (max-width: ${OwnConfig.breakPointTablet}px) {
    display: none;
  }

  background-color: #e6e6e6;
  position: sticky;
  top: ${AppConfig.global.navbarHeight}px;
  display: flex;
  align-self: flex-start;
  flex-direction: column;
  padding: 10px 20px;
  width: 200px;
  min-height: 100vh;
  justify-content: flex-start;
`;

class MobileFilterPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false
    };
  }

  render() {
    let { activeFilter, onFilterItemClick, labels } = this.props;
    let { visible } = this.state;

    return (
      <MobileWrapper visible={visible}>
        {visible && (
          <MobileBackdrop onClick={() => this.setState({ visible: false })} />
        )}

        <button
          className="toggle-btn"
          onClick={() => this.setState({ visible: true })}
        >
          分類
        </button>

        <MobileContainer visible={visible}>
          <FilterTitle>
            <Icon.Menu color="#4e4e4e" size={24} style={{ marginRight: 10 }} />
            <div>分類</div>
          </FilterTitle>

          {labels.map((label, lidx) => {
            if (label.children.length > 0) {
              return (
                <Fragment key={lidx}>
                  {label.children.map((children, index) => (
                    <TreeNodeWrapper
                      key={index}
                      onNodeClick={node => onFilterItemClick(node)}
                      activeNode={activeFilter}
                      node={children}
                    />
                  ))}
                </Fragment>
              );
            }
          })}
        </MobileContainer>
      </MobileWrapper>
    );
  }
}

const MobileWrapper = styled.div`
  @media screen and (min-width: ${OwnConfig.breakPointTablet}px) {
    display: none;
  }

  position: fixed;
  right: 16px;
  bottom: 16px;
  z-index: 100;
  background: transparent;

  & > .toggle-btn {
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    width: ${props => (props.visible ? "40px" : "54px")};
    height: ${props => (props.visible ? "40px" : "54px")};
    border-radius: ${props => (props.visible ? "20px" : "27px")};
    font-size: ${props => (props.visible ? "8px" : "initial")};
    border: none;
    box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.5);
    transition: all 200ms;
  }

  & > .toggle-btn:focus {
    outline: none;
  }
`;

const MobileBackdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.2);
`;

const MobileContainer = styled.div`
  position: fixed;
  top: 0px;
  left: ${props => (props.visible ? "0px" : "-200px")};
  background-color: white;
  width: 200px;
  min-height: 100vh;
  padding: 10px;
  overflow: auto;
  transition: left 200ms;
`;

export default FilterPanel;
