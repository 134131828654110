import React from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import ActionCreator from "../../ActionCreator";
import { dateFormat } from "../../Utils/DateUtil";
import * as Widget from "../Widget";
import CmsView from "../CmsView";
import { getDisplay } from "../../Utils/TreeUtil";
import jsonParser from "../../Utils/JsonParser";
import labels from "../../Domain/ArticleTree";
import Image from "../Image";

const Wrapper = styled.div`
  .image-container {
    margin: 0px 100px;
    width: 100% - 200px;
    height: 440px;

    @media screen and (max-width: 600px) {
      margin: 0px;
      height: 300px;
    }
  }
`;

const Header = styled.div`
  margin: 30px 0px;

  h1 {
    text-align: center;
  }

  p {
    text-align: center;
    color: #0000008a;
    font-size: 14px;
  }

  & .labels-wrapper {
    display: flex;

    & > .label {
      margin: 5px;
      padding: 5px 10px;
      border-radius: 15px;
      background-color: #ccc;
      color: white;
      font-size: 14px;
      cursor: pointer;
    }
  }
`;

const Content = styled.div`
  margin: 50px 140px;
  padding: 20px;

  @media screen and (max-width: 600px) {
    margin: 0px;
  }
`;

class ArticleView extends React.Component {
  render() {
    let { item, navActions } = this.props;

    if (!item) {
      return (
        <div style={{ width: "100%" }}>
          <Widget.Center css="padding: 20px;">
            <Widget.Spinner />
          </Widget.Center>
        </div>
      );
    }

    return (
      <Wrapper>
        <Header>
          <h1>{item.name}</h1>
          <p>{dateFormat(item.created)}</p>
          <Widget.Row css={{ justifyContent: "center", alignItems: "center" }}>
            <div className="labels-wrapper">
              {jsonParser(item.label, []).map((l, idx) => {
                let name = getDisplay(labels[0], l);
                return name ? (
                  <div
                    className="label"
                    key={idx}
                    onClick={() => navActions.push(`/articles/${l}`)}
                  >
                    {name}
                  </div>
                ) : null;
              })}
            </div>
          </Widget.Row>
        </Header>

        <div className="image-container">
          <Image src={item.image} background />
        </div>
        <Content>
          <CmsView cmsContent={item.content} />
        </Content>
      </Wrapper>
    );
  }
}

ArticleView = connect(
  null,
  ActionCreator
)(ArticleView);

export default ArticleView;
