import React, { Component } from "react";
import styled from "styled-components";
import Constants from "../Domain/constants";

let Wrapper = styled.div`
  width: 100%;

  & img {
    max-width: 100%;
    height: auto !important;
  }
  ${props => props.extraStyle}
`;

class CmsView extends Component {
  render() {
    let { cmsContent, extraStyle = "" } = this.props;

    return (
      <Wrapper
        dangerouslySetInnerHTML={{ __html: this._insertMediaHost(cmsContent) }}
        className="cmsContent"
        extraStyle={extraStyle}
      />
    );
  }

  _insertMediaHost = string => {
    const mediaPathPattern = /(<img\s+[^>]*src=")(\/media\/[^"]+)/g;
    string = string.replace(mediaPathPattern, `$1${Constants.apiUrl}$2`);
    return string;
  };
}

export default CmsView;
